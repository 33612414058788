.loading-fadein {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
  visibility: hidden;
  animation: fadein 2s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s; /* no spinner flickering for fast load times */
}

@keyframes fadein {
  from {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}

#loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #999;
  margin: 35px 5px;
  opacity: 0;
}

#loader span:nth-child(1) {
  animation: opacitychange 1s ease-in-out infinite;
}

#loader span:nth-child(2) {
  animation: opacitychange 1s ease-in-out 0.33s infinite;
}

#loader span:nth-child(3) {
  animation: opacitychange 1s ease-in-out 0.66s infinite;
}

@keyframes opacitychange {
  0%,
  100% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}
